<template>
  <el-card class="box-card" :body-style="{ padding: '10px' }">
    <el-row class="row" :gutter="20">
      <el-col :span="4">
        <el-image
            class="image"
            :src="item.articleImage"
            fit="fill"></el-image>
      </el-col>
      <el-col :span="20">
        <div class="right">
          <h1 class="title">
            <a >{{ item.articleTitle }}</a>
          </h1>
          <div class="desc">
            <p> {{ item.articleContext }}</p>
          </div>
          <div class="date_hits">
            <span>
              <i class="fa fa-user"></i>
              {{ item.username }}
            </span>
            <span>
              <i class="fa fa-calendar"></i>
              {{ item.gmtCreate }}
            </span>
<!--            <span>{{ item.tag }}</span>-->
            <span class="hits">
              <i class="fa fa-eye"></i>
              {{item.articleViews}}
            </span>
            <span class="comment">
              <i class="fa fa-thumbs-up"></i>
              {{item.articleGood}}
            </span>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import "font-awesome/css/font-awesome.min.css";

export default {
  name: "item",
  props: {
    item: {
      type: Object,
      default() {
        return {
          articleBad: 0,
          articleContext: "# sa \nsa",
          articleGood: 1,
          articleImage: "https://fakerlove.oss-cn-beijing.aliyuncs.com/kaoyan/2021/05/06/1b7520df4e43475c887c974e661af4a9素材1.jpg",
          articleStar: 1,
          articleTitle: "拉拉",
          articleViews: 1,
          gmtCreate: "2021-05-06 17:14:21",
          id: "1390233462700183553",
          tags: null,
          userId: "1367796396955774977",
          userImg: "https://fakerlove.oss-cn-beijing.aliyuncs.com/kaoyan/2021/04/10/5452a951b4c543f59cc656d139c37a99头像2.jpg",
          username: "test01"
        }
      }
    }
  }
}
</script>

<style scoped>
/* 左边*/
.image {
  width: 100%;
  height: 110px;
}

.row {
  height: 110px;
}

/* 右边*/

/*.hits {*/
/*  position: absolute;*/
/*  right: 60px;*/
/*  top: 0;*/
/*}*/

/*.comment {*/
/*  position: absolute;*/
/*  right: 0;*/
/*  top: 0;*/
/*}*/

.date_hits span {
  padding-right: 15px;
  height: 20px;
  line-height: 20px;
}

.right {
  display: flex;
  /*修改主轴方向*/
  flex-direction: column;
  text-align: left;
}

.title {
  max-width: 99%;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 20px;
  line-height: 40px;
  height: 40px;
}

.desc {
  height: 40px;
  line-height: 24px;
  overflow: hidden;
  /*margin-top: 8px;*/
  color: #444;
}
.desc p{
  height: 40px;
  line-height: 40px;
  overflow: hidden;
}
/* 底部信息*/
.date_hits {
  position: relative;
  height: 30px;
  font-size: 14px;
  color: #666;
  display: flex;
}
.date_hits span{
  line-height: 30px;
  height: 30px;
}
</style>
