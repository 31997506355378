<template>
  <div class="con">
    <el-row>
      <el-col :span="18">
        <el-row class="blog">
          <el-row v-for="(item,index) in blog.records" :key="index" class="blog" @click.native="clickBlog(item.id)">
            <item :item="item"></item>
          </el-row>
          <el-pagination
              background
              :current-page="blog.current"
              :page-size="blog.size"
              @current-change="change"
              layout="prev, pager, next"
              :total="blog.total">
          </el-pagination>
        </el-row>
      </el-col>
      <el-col :span="6">
        <faqbox></faqbox>
        <faqbox></faqbox>
        <rank></rank>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {getArticleAll, getArticleById} from "@/api/article";
import {utc2beijing} from "@/utils/time";

import faqbox from "@/views/blog/faqbox";
import rank from "@/views/blog/rank";
import item from "@/views/blog/item";

export default {
  name: "All",
  components: {
    item,
    faqbox,
    rank
  },
  data() {
    return {
      blog: ""
    }
  },
  mounted() {
    this.change(1)
  },
  methods: {
    utc2beijing,
    // 展示文章信息
    clickBlog(articleId) {
      console.log("点击了博客" + articleId);
      this.$router.push({path: "/blog/show/", query: {articleId}})
    },
    change(number) {
      // 获取所有文章信息
      getArticleAll(number).then(result => {
        console.log(result)
        this.blog = result.data.article;
      }).catch(error => {
        // 输出错误信息
        this.$message(error.message)
      })
    }
  },

}
</script>

<style scoped>
.blog {
  margin-bottom: 10px;
}
.con{
  width: 90%;
  margin: auto;
}

</style>
