<template>
  <div class="faqbox">
    <div class="title">
      Are bears just about to extinct?
    </div>
    <div class="content">
      Yes! But if you’d like to buy us a slice of cheap pizza, we’ll reward you with some cool bonus features.
    </div>
  </div>
</template>

<script>
export default {
  name: "faqbox"
}
</script>

<style scoped>
.faqbox{
  overflow: hidden;
  background: #f9f9f9;
  border: 1px solid #e7e7e7;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  padding-left: 0px;
  padding-right: 0px;
  /*width: 28%;*/
  float: left;
  margin: 0 2.5% 30px 2.5%;
}
.title{
  text-align: left;
  font-size: 14px;
  color: #000;
  font-weight: normal;
  padding: 17px 0 17px 60px;
  margin: 0 0 0 0;
  background: #fff url(../../assets/image/i6.png) no-repeat 25px center;
  border-bottom: 1px solid #e7e7e7;
}
.content{
  text-align: left;
  font-size: 14px;
  color: #5f5f5f;
  line-height: 24px;
  margin: 25px;
}
</style>
