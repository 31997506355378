<template>
  <div class="ty-play">
    <h3 class="g-title">
      <strong>
        <i></i>
        <b>电视剧榜</b>
      </strong>
    </h3>
    <ul class="ul">
      <li v-for="(item ,index) in rank" :key="index">
        <i>{{index}}</i>
        <a :href="item.target"
           :title="item.title"
           target="_blank">{{item.name}}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "rank",
  data() {
    return {
      rank: [
        {
          name: "《九州缥缈录》华美大婚今晚上演，刘昊",
          target: "http://zf020810.demo.kangjingept.com/demo951/a/dianshiju/72.html",
          title: "《九州缥缈录》华美大婚今晚上演，刘昊"
        }, {
          name: "《九州缥缈录》华美大婚今晚上演，刘昊",
          target: "http://zf020810.demo.kangjingept.com/demo951/a/dianshiju/72.html",
          title: "《九州缥缈录》华美大婚今晚上演，刘昊"
        },
        {
          name: "《九州缥缈录》华美大婚今晚上演，刘昊",
          target: "http://zf020810.demo.kangjingept.com/demo951/a/dianshiju/72.html",
          title: "《九州缥缈录》华美大婚今晚上演，刘昊"
        }
      ]
    }
  }
}
</script>

<style scoped>
.ty-play{
  background-color: #fff;
  padding: 5px;
  margin-left: 10px;
  width: 100%;
  height: auto;
  overflow: hidden;
}
.ul{
  display: block;
  overflow: hidden;
  margin: 8px 0 0;
}
.ul li{
  height: 20px;
  margin: 12px 0 0;
  display: block;
  overflow: hidden;
  list-style: none;
}
.ul li a{
  width: 132px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: normal;
  color: #333;
  float: left;
  margin: 0 0 0 12px;
  display: inline;
  overflow: hidden;
}
.ul li i{
  width: 20px;
  height: 20px;
  line-height: 20px;
  background: #aaa;
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
  color: #fff;
  font-style: normal;
  text-align: center;
  float: left;
  margin: 0;
  display: inline;
  overflow: hidden;
}
.g-title{
  height: 30px;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.g-title strong{
  height: 30px;
  float: left;
  margin: 0;
  display: inline;
  overflow: hidden;
  font-weight: bolder;
}
.g-title strong i{
  width: 5px;
  height: 30px;
  background: #ffbe00;
  float: left;
  margin: 0;
  display: inline;
  overflow: hidden;
}
.g-title strong b{
  height: 30px;
  line-height: 30px;
  font-size: 24px;
  font-weight: normal;
  color: #333;
  margin: 0 18px;
  float: left;
  display: inline;
  overflow: hidden;
}


</style>
